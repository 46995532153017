<template>
  <div class="panel">
    <div class="cardth">
      <div class="head">
        <div class="line">
          <el-select 
              v-model="sheng" 
              placeholder="省" 
              filterable
              style="width: 100px;" 
              @change="handleChange($event,1)"
          >
              <el-option
                v-for="(item,index) in addressList"
                :key="index + 'sheng'"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
          </el-select>
          <el-select 
              v-model="shi" 
              placeholder="市" 
              filterable
              style="width: 100px; margin-left: 20px;" 
              @change="handleChange($event,2)"
          >
              <el-option
                v-for="(item,index) in shiList"
                :key="index + 'shi'"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
          </el-select>
          <el-select 
              v-model="xian" 
              placeholder="县" 
              filterable
              style="width: 100px; margin-left: 20px;" 
              @change="handleChange($event,3)"
          >
              <el-option
                v-for="(item,index) in xianList"
                :key="index + 'shi'"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
          </el-select>
          <el-select 
            v-model="deviceEnd" 
            filterable
            @change="selectType($event)"
            style="margin-left: 20px;width: 220px;"
          >
            <el-option 
                v-for="item in marker"
                :key="item.id"
                :value="item.device"
                :label="item.device_name"
            >
            </el-option>
          </el-select>
        </div>
        <span class="history">
          <el-button @click="pr('moon',3)" :type="butprimary.moon">月</el-button>
          <el-button @click="pr('week',2)" :type="butprimary.week">周</el-button>
          <el-button @click="pr('sun',1)" :type="butprimary.sun">日</el-button>
          <div class="block">
            <el-date-picker 
                v-if="dataType === 2"
                @change="getweek"
                v-model="timedata.week"
                :picker-options="setDisabled"
                type="week"
                format="yyyy 第 WW 周"
                value-format="yyyy-MM-dd"
                placeholder="选择周"
            >
            </el-date-picker>
            <el-date-picker 
                v-if="dataType === 3"
                @change="getmoon"
                v-model="timedata.moon"
                :picker-options="setDisabled"
                type="month"
                format="yyyy-MM"
                value-format="yyyy-MM"
                placeholder="选择月"
            >
            </el-date-picker>
            <el-date-picker 
                v-if="dataType === 1"
                @change="getsun"
                v-model="timedata.sun"
                :picker-options="setDisabled"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </div>
        </span>
        <span class="export" @click="downloadsc">导出图表数据</span>
      </div>
    </div>
    <!-- charts图 -->
    <div class="card">
      <div v-for="(item,index) in chartOptionsMax" :key="index">
        <div v-if="dataType === 3">
          <h3>{{ item.name }}</h3>
          <v-chart 
              class="chart"
              style="margin-bottom: 20px;"
              :option="item.option"
              :autoresize="true" 
          />
        </div>
      </div>
      <div v-for="(item,index) in chartsData" :key="index">
        <h3>{{ item.name }}</h3>
        <v-chart 
            class="chart"
            style="margin-bottom: 20px;"
            :option="item.chartOptions"
            :autoresize="true" 
        />
      </div>
    </div>

    <!-- 下载 -->
    <div class="ancklick" :class="{'anc2':anclk}">
      <h1>
        <span>下载选择</span>
        <span class="iconqq" @click="downloadsc" >x</span>
      </h1>
      <div>
        <el-button 
            @click="boot(2)"
            :type="tppring == 2 ? 'primary' : ''"
        >
            小时数据
        </el-button>
        <el-button 
            @click="boot(1)"
            :type="tppring == 1 ? 'primary' : ''"
        >
            分钟数据
        </el-button>
        <el-button 
            @click="boot(3)"
            type="warning"
            style="margin-left: 50px;"
        >
          下载
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { addressList } from './cityData'
import { Loading } from 'element-ui';  
import dayjs from "dayjs";
import { $device } from "@/services";
import { toXlsx } from '@/utils/xlsx.js'
import {chartOption ,getChinese} from './chartsOption'
import {maxchartoptionhelper} from './maxoptions'
export default {
  name: "GisDetail",
  data () {
    return {
      butprimary: {
        sun: 'primary',
        moon: '',
        week: '',
        datatimes: 1, 
      },
      timedata: {
        sun: new Date(),
        moon: new Date(),
        week: new Date(),
      },
      region: '',
      items: [],
      checkTime: dayjs().subtract(2, "m").format("YYYYMMDDHHmm").concat("00"),// 时间
      timerange: [
        dayjs().format("YYYYMMDD000000"),
        dayjs().subtract(-1, "day").format("YYYYMMDD000000"),
      ],
      period: "month",
      marker: [],
      deviceEnd: '',
      device_name: '',
      device_type: '',
      anclk: false,
      listget1: [],
      listget2: [],
      tppring: 2,
      dataType:1,
      setDisabled: {
        disabledDate (time) {
          return time.getTime() > Date.now();// 可选历史天、可选当前天、不可选未来天 
        },
      },
      chartsData:[],
      chartOptionsMax:[],
      addressList,
      sheng:'',
      shiList:[],
      shi:'',
      xianList:[],
      xian: ''

    };
  },
  created () {
    this.getConnect();
    // this.getData()

  },
  methods: {
    // 获取气象数据
    async getData() {
        let params = {}
        params.type = this.dataType
        params.startTime = this.timerange[0]
        params.endTime = this.timerange[1]
        params.device = this.deviceEnd
        let res = await $device.weatherDetail(params)
        let data = res.data
        let obj = {tempIn:[],tempOut:[],humidityOut:[],humidityIn:[],hpaAbs:[],windSpeed:[],windDir:[],rain:[],solarRadiation:[],uv:[],time:[]}
        // 循环处理请求数据
        data.forEach(item => {
            obj.hpaAbs.push(item.hpaAbs)
            obj.humidityIn.push(item.humidityIn)
            obj.humidityOut.push(item.humidityOut)
            obj.rain.push(item.rain)
            obj.tempIn.push(item.tempIn)
            obj.tempOut.push(item.tempOut)
            obj.solarRadiation.push(item.solarRadiation)
            obj.uv.push(item.uv)
            obj.windDir.push(item.windDir)
            obj.windSpeed.push(item.windSpeed)
            obj.time.push(item.time)
        })
        let arr = []
        let keys = Object.keys(obj)
        // 循环生成charts需要的数据
        let times = []
        obj.time.forEach(v => {
          v = v + ''
          v = v.slice(0,4) + '-' + v.slice(4,6) + '-' + v.slice(6,8) + ' ' + v.slice(8,10) + ':' + v.slice(10,12) + ':' + v.slice(12,14)
          times.push(v)
        })
        keys.forEach(item => {
          let chartsObj = {}
          if (item === 'tempIn') {
            chartsObj.name = '温度(℃)'
            let data = ["室内温度(℃)","室外温度(℃)"]
            let series =[
              {
                name: "室内温度(℃)",
                type: "line",
                smooth: true,
                data: obj.tempIn,
              },
              {
                name: "室外温度(℃)",
                type: "line",
                smooth: true,
                data: obj.tempOut,
              },
            ]
            chartsObj.chartOptions = chartOption(data,series,this.period,times)
            arr.push (chartsObj)
          } else if(item === 'humidityIn') { 
            chartsObj.name = '湿度(%)'
            let data = ["室内湿度(%)","室外湿度(%)"]
            let series =[
              {
                name: "室内湿度(%)",
                type: "line",
                smooth: true,
                data: obj.humidityIn,
              },
              {
                name: "室外湿度(%)",
                type: "line",
                smooth: true,
                data: obj.humidityOut,
              },
            ]
            chartsObj.chartOptions = chartOption(data,series,this.period,times)
            arr.push (chartsObj)
          } else if (item === 'humidityOut' || item === 'tempOut' || item === 'hpaRel') {
            console.log('无效数据')
          } else if (item === 'rain') {
            chartsObj.name = getChinese(item)
            let data = [getChinese(item)]
            let series =[
              {
                name: getChinese(item),
                type: "bar",
                barWidth: '20',
                smooth: true,
                data: obj[item],
              },
            ]
            chartsObj.chartOptions = chartOption(data,series,this.period,times)
            arr.push(chartsObj)
          } else {
            chartsObj.name = getChinese(item)
            let data = [getChinese(item)]
            let series =[
              {
                name: getChinese(item),
                type: "line",
                smooth: true,
                data: obj[item],
              },
            ]
            chartsObj.chartOptions = chartOption(data,series,this.period,times)
            arr.push(chartsObj)
          }
        })
        this.chartsData = arr
    },
    //时间选择器
    pr (value, nb) {
      for (let key in this.butprimary) {
        this.butprimary[key] = ''
      }
      this.butprimary[value] = 'primary'
      this.butprimary.datatimes = nb
      this.dataType = nb
      if (nb == 2) {
        this.getweek()
      }
      if (nb == 3) {
        this.getmoon()
      }
      if (nb == 1) {
        this.getsun()
      }
    },
    //打开关闭下载框，默认选择小时数据
    downloadsc () {
      this.anclk = !this.anclk
      this.tppring = 2
    },
    //下载表格   分钟小时数据切换
    async boot(value) {
      if (value !== 3) {
        this.tppring = value
      } else {
        let params = {}
        let col = []
        if (this.tppring === 2) {
          col = [
            { prop: 'index',label: '序号'},
            { prop: 'upDate', label: '上传日期(北京时/GMT+8)'},
            { prop: 'upTime', label: '上传时间'},
            { prop: 'tempIn', label: '室内温度(℃)' },
            { prop: 'tempOut', label: '室外温度(℃)' },
            { prop: 'humidityIn', label: '室内湿度(%)' },
            { prop: 'humidityOut', label: '室外湿度(%)' },
            { prop: 'hpaAbs', label: '气压(hPa)',  }, 
            { prop: 'windSpeed', label: '风速(m/s)',  },
            { prop: 'windDir', label: '风向(°)',  },
            { prop: 'maxDayWind', label: '每日最大风(m/s)',  },
            { prop: 'windSpeed2Min', label: '2分钟平均风速(m/s)',  },
            { prop: 'windSpeed10Min', label: '10分钟平均风速(m/s)',  },
            { prop: 'windDir2Min', label: '2分钟平均风向',  },
            { prop: 'windDir10Min', label: '10分钟平均风向',  },
            { prop: 'rain', label: '小时降雨量(mm)',  },
            { prop: 'dayRain', label: '日降雨量(mm)',  },
            { prop: 'monthRain', label: '月降雨量(mm)',  },
            { prop: 'yearRain', label: '年降雨量(mm)',  },
            { prop: 'solarRadiation', label: '太阳辐射(W/m2)',  },
            { prop: 'uv', label: '紫外线辐射指数',  },
            
          ]
        } else {
          col = [
              { prop: 'index',label: '序号'},
              { prop: 'upDate', label: '上传日期(北京时/GMT+8)'},
              { prop: 'upTime', label: '上传时间'},
              { prop: 'tempIn', label: '室内温度(℃)' },
              { prop: 'tempOut', label: '室外温度(℃)' },
              { prop: 'humidityIn', label: '室内湿度(%)' },
              { prop: 'humidityOut', label: '室外湿度(%)' },
              { prop: 'hpaAbs', label: '气压(hPa)',  },  
              { prop: 'windSpeed', label: '风速(m/s)',  },
              { prop: 'windDir', label: '风向(°)',  },
              { prop: 'maxDayWind', label: '每日最大风(m/s)',  }, 
              { prop: 'windSpeed2Min', label: '2分钟平均风速(m/s)',  },
              { prop: 'windSpeed10Min', label: '10分钟平均风速(m/s)',  },
              { prop: 'windDir2Min', label: '2分钟平均风向',  },
              { prop: 'windDir10Min', label: '10分钟平均风向',  },
              { prop: 'rain', label: '分钟降雨量(mm)',  },
              { prop: 'solarRadiation', label: '太阳辐射(W/m2)',  },
              { prop: 'uv', label: '紫外线辐射指数',  },
          ]
        }
        params.type = this.tppring
        params.startTime = this.timerange[0]
        params.endTime = this.timerange[1]
        params.device = this.deviceEnd
        let res = await $device.expoetExcl(params)
        let data = res.data
        let columns = col
        data.forEach((item,index) => {
          item.index = index + 1
          item.time = item.time + ''
          item.upDate = item.time.slice(0,4) + '.' + item.time.slice(4,6) + '.' + item.time.slice(6,8)
          item.upTime = item.time.slice(8,10) + ':' + item.time.slice(10,12)
        })
        let dataSource = data
        let obj = this.marker.find(item => item.device === this.deviceEnd)
        let fileName = (params.type === 2 ? '小时-' : '分钟-') + obj.id + '.' + obj.device_name + '(' + params.startTime.slice(0,8) + '-' + params.endTime.slice(0,8) + ')'
        let sheet = obj.id + '.' + obj.device_name
        let exclParams = {columns,dataSource,fileName,sheet}
        toXlsx(exclParams)
        this.anclk = false
      }
    },
    getiok (value, less) {
      //延时
      const loading = this.$loading({
        lock: true,
        text: '下载数据中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let loadingInstance = Loading.service();
      function atm () {
        loading.close();
        loadingInstance.close();
      }
      setTimeout(atm, 50000);
      let [startTime, endTime] = this.timerange
      return $device.getDeviceHourData({
        device: this.deviceEnd,
        startTime,
        endTime,
        period: value,
      }).then((res) => {
        let data = res.data;
        this[less] = data.map(function (item) {
          return {
            date: item.time.slice(0, 4) + "." + item.time.slice(4, 6) + "." + item.time.slice(6, 8),
            time: item.time.slice(8, 10) + ":" + item.time.slice(10, 12),
            tempinf: item.tempinf,
            tempf: item.tempf,
            humidityin: item.humidityin,
            humidity: item.humidity,
            baromabsin: item.baromabsin,
            baromrelin: item.baromrelin,
            maxdailygust: item.maxdailygust,
            windgustmph: item.windgustmph,
            windspeedmph: item.windspeedmph,
            winddir: item.winddir,
            windspdmph_avg2m: item.windspdmph_avg2m,
            winddir_avg2m: item.winddir_avg2m,
            windspdmph_avg10m: item.windspdmph_avg10m,
            winddir_avg10m: item.winddir_avg10m,
            minutesrainin: item.minutesrainin,
            hourlyrainin: item.hourlyrainin,
            dailyrainin: item.dailyrainin,
            weeklyrainin: item.weeklyrainin,
            monthlyrainin: item.monthlyrainin,
            yearlyrainin: item.yearlyrainin,
            totalrainin: item.totalrainin,
            solarradiation: item.solarradiation,
            uv: item.uv,
          }
        })
        this.download(less, () => {
          this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
          });
        })
      })
    },
    getConnect (webId) {
      let that = this;
      that.marker = []
      $device.getConnectData({
        value: "status",
        time: this.checkTime,
        device_name: '',
        webId:webId || ''
      }).then((res) => {
        if (res.result === 1) {
          if (res.data.length) {
            that.marker = res.data
            that.deviceEnd = res.data[0].device
            that.device_name = res.data[0].device_name
            that.device_type = res.data[0].device_type
          } else {
            that.marker = []
            that.deviceEnd = ''
            that.device_name = ''
            that.device_type = ''
          }
         
          this.getData()
          this.getmaxandmin()
        }
      });
    },
    selectType (event) {
      this.deviceEnd = event; //获取option对应的value值 select_class_id是后台约定的提交数据的名称
      this.device_name = this.marker[this.marker.findIndex(item => item.device === this.deviceEnd)].device_name
      this.device_type = this.marker[this.marker.findIndex(item => item.device === this.deviceEnd)].device_type
      this.getData()
      this.getmaxandmin()
    },
    getmoon () {
      this.timerange = [
        dayjs(this.timedata.moon).format("YYYYMM01000000"),
        dayjs(this.timedata.moon).subtract(-1, "month").format("YYYYMM01000000")
      ]
      this.period = "month"
      this.getData()
      this.getmaxandmin()
    },
    getsun () {
      this.period = "day"
      this.timerange = [
        dayjs(this.timedata.sun).format("YYYYMMDD000000"),
        dayjs(this.timedata.sun).subtract(-1, "day").format("YYYYMMDD000000"),
      ]
      this.getData()
    },
    getweek () {
      this.period = "week"
      this.timerange = [
        dayjs(this.timedata.week).startOf('week').format('YYYYMMDD000000'),
        dayjs(this.timedata.week).endOf('week').format('YYYYMMDD000000'),
      ]
      this.getData()
      this.getmaxandmin()
    },
    async getmaxandmin() {
      let params = {}
      params.startTime = this.timerange[0]
      params.endTime = this.timerange[1]
      params.device = this.deviceEnd
      let res = await $device.getMaxAndMinTem(params)
      // 温度
      let maxArrall = [];let minArrall = [];let timeArrall = [];let maxArr1all = [];let minArr1all = []
      res.data.forEach(item => {
        let maxArr = {type:'',val:[]};let minArr = {type:'',val:[]};let timeArr = {type:'',val:[]};let maxArr1 = {type:'',val:[]};let minArr1 = {type:'',val:[]}
        if(item.statType === 1) {
            maxArr.type = item.type
            maxArr.val = item.dayVal
            let v = item.dayTime + ''
            let t = item.time + ''
            maxArr1.val = {value:item.dayVal ,time: t.slice(8,10) + ':' + t.slice(10,12) + ':' + t.slice(12,14),date:t.slice(0,4) + '-' + t.slice(4,6) + '-' + t.slice(6,8)}
            maxArr1.type = item.type
            timeArr.val = v.slice(0,4) + '-' + v.slice(4,6) + '-' + v.slice(6,8)
            timeArr.type = item.type
          } else if (item.statType === 2) {
            let t = item.time + ''
            minArr1.val = ({value:item.dayVal ,time: t.slice(8,10) + ':' + t.slice(10,12) + ':' + t.slice(12,14),date:t.slice(0,4) + '-' + t.slice(4,6) + '-' + t.slice(6,8)})
            minArr1.type = item.type
            minArr.val = (item.dayVal)
            minArr.type = item.type
          }
          maxArrall.push(maxArr)
          minArrall.push(minArr)
          timeArrall.push(timeArr)
          maxArr1all.push(maxArr1)
          minArr1all.push(minArr1)
      })
      this.maxAndMinDataHelper({maxArrall,minArrall,timeArrall,maxArr1all,minArr1all})
    },
    // 处理最高最低数据
    maxAndMinDataHelper(data){
      this.chartOptionsMax = []
      let maxTem = [],minTem = [], maxTem1=[], minTem1=[],temTime = []
      let maxHum = [],minHum = [], maxHum1=[], minHum1=[],humTime = []
      let maxHpa = [],minHpa = [], maxHpa1=[], minHpa1=[],hpaTime = []
      let maxWind = [],minWind = [], maxWind1=[], minWind1=[], windTime = []
      let maxSun = [],minSun = [], maxSun1=[], minSun1=[],sunTime = []
      let maxuv = [],minuv = [], maxuv1 = [], minuv1=[],unTime = []
      data.maxArrall.forEach(item => {
          if (item.type === 1) {
            maxTem.push(item.val)
          } else if (item.type === 2) {
            maxHum.push(item.val)
          } else if (item.type === 3) {
            maxHpa.push(item.val)
          } else if (item.type === 4) {
            maxWind.push(item.val)
          } else if (item.type === 5) {
            maxSun.push(item.val)
          } else if (item.type === 6) {
            maxuv.push(item.val)
          }
      })
      data.minArrall.forEach(item => {
          if (item.type === 1) {
            minTem.push(item.val)
          } else if (item.type === 2) {
            minHum.push(item.val)
          } else if (item.type === 3) {
            minHpa.push(item.val)
          } else if (item.type === 4) {
            minWind.push(item.val)
          } else if (item.type === 5) {
            minSun.push(item.val)
          } else if (item.type === 6) {
            minuv.push(item.val)
          }
      })
      data.maxArr1all.forEach(item => {
          if (item.type === 1) {
            maxTem1.push(item.val)
          } else if (item.type === 2) {
            maxHum1.push(item.val)
          } else if (item.type === 3) {
            maxHpa1.push(item.val)
          } else if (item.type === 4) {
            maxWind1.push(item.val)
          } else if (item.type === 5) {
            maxSun1.push(item.val)
          } else if (item.type === 6) {
            maxuv1.push(item.val)
          }
      })
      data.minArr1all.forEach(item => {
          if (item.type === 1) {
            minTem1.push(item.val)
          } else if (item.type === 2) {
            minHum1.push(item.val)
          } else if (item.type === 3) {
            minHpa1.push(item.val)
          } else if (item.type === 4) {
            minWind1.push(item.val)
          } else if (item.type === 5) {
            minSun1.push(item.val)
          } else if (item.type === 6) {
            minuv1.push(item.val)
          }
      })
      data.timeArrall.forEach(item => {
          if (item.type === 1) {
            temTime.push(item.val)
          } else if (item.type === 2) {
            humTime.push(item.val)
          } else if (item.type === 3) {
            hpaTime.push(item.val)
          } else if (item.type === 4) {
            windTime.push(item.val)
          } else if (item.type === 5) {
            sunTime.push(item.val)
          } else if (item.type === 6) {
            unTime.push(item.val)
          }
      })
      // 处理温度charts
      this.chartOptionsMax.push({name:'最高气温&最低气温',option:maxchartoptionhelper('最高温度°C','最低温度°C',maxTem,maxTem1,minTem,minTem1,temTime)})
      // 处理湿度charts
      this.chartOptionsMax.push({name:'最高湿度&最低湿度',option : maxchartoptionhelper('最高湿度(%)','最低湿度(%)',maxHum,maxHum1,minHum,minHum1,humTime)})
      // 处理气压charts
      this.chartOptionsMax.push({name:'最高气压&最低气压',option: maxchartoptionhelper('最高气压hPa','最低气压hPa',maxHpa,maxHpa1,minHpa,minHpa1,hpaTime)})
      // 处理 风速charts
      this.chartOptionsMax.push({name:'最大风速&最低风速',option:maxchartoptionhelper('最高风速m/s','最低风速m/s',maxWind,maxWind1,minWind,minWind1,windTime)})
      // 处理光照
      this.chartOptionsMax.push({name:'最强光照&最低光照',option: maxchartoptionhelper('最高光照w/m²','最低光照w/m²',maxSun,maxSun1,minSun,minSun1,sunTime)})
      // uv charts
      this.chartOptionsMax.push({name:'最强紫外线&最弱紫外线',option: maxchartoptionhelper('最高紫外线强度','最低紫外线强度',maxuv,maxuv1,minuv,minuv1,unTime) })
    },
    // 选择省
    handleChange(e,type) {
      if (type === 1) {
        let obj = this.addressList.find(item => item.code === e)
        this.shi = ''
        this.shiList = []
        this.xian = ''
        this.xianList = []
        this.shiList = obj.children
      } else if (type === 2) {
        let shiObj = this.shiList.find(item => item.code === e)
        this.xian = ''
        this.xianList = []
        this.xianList = shiObj.children
      }
      this.getConnect(e)
    },
  },
};
</script>
<style lang="less" scoped>
/* 滚动条整体 */
::-webkit-scrollbar { 
  width: 20px;
  background-color: #283142;
}

/* 滚动条两端按钮 */
::-webkit-scrollbar-button {
  // 左侧、上侧
  &:decrement {
    background: #323e4e;
    border-radius: 6px 6px 0px 0px;
  }
  // 右侧、下侧
  &:increment {
    background: #323e4e;
    border-radius: 6px 6px 0px 0px;
  }
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #283142;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #005770;
}

/deep/ .el-range-editor.el-input__inner {
  background: transparent;
}
/deep/ .el-range-input {
  background: transparent;
  color: #00a0e9;
}
/deep/ .el-date-editor--datetimerange.el-input__inner {
  width: 450px !important;
  height: 35px;
}
/deep/ .el-range-separator {
  height: 30px;
  color: #00a0e9;
}
.panel {
  padding: 20px;
  width: 100%;
  height: 100%;
  // background: #1b2531;
  .cardth {
    border-radius: 20px;
    padding: 0px 40px 20px 20px;
    // background: #1b2531;
    .head {
      height: 100px;
      width: 100%;
      padding: 30px;
      border-radius: 20px;
      // background: #666;
      display: flex;
      align-items: center; /*垂直居中*/
      justify-content: space-between; /*水平居中*/
      .line {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        select {
          flex: 1;
          height: 35px;
          width: 450px;
          text-align: center;
          font-size: 16px;
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          line-height: 35px;
          background-color: #323e4e;
          // border: 1px solid #666;
          color: #ffffff;
          border: none;
          outline: none;
          border-radius: 10px;
          padding: 0 0.5em;
          option {
            flex: 1;
            font-size: 16px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            line-height: 35px;
            background-color: #323e4e;
            color: #ffffff;
            border-radius: 0.3em;
            padding: 0 0.5em;
          }
        }
      }
      .history {
        display: flex;
      }
      .export {
        cursor: pointer;
        user-select: none;
        line-height: 35px;
        height: 35px;
        border-radius: 10px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        overflow: hidden;
        width: 150px;
        background: linear-gradient(90deg, #3ab87f 0%, #0ab1e4 100%);
        border-radius: 10px;
      }
    }
  }
  .card {
    border-radius: 20px;
    padding: 0px 20px 20px 20px;
    position: relative;
    // background: #1b2531;
    // overflow-y: scroll;
    // overflow-x: hidden;
    height: 85vh;
    // &.dark {
    //   background: #283142;
    // }
    h3 {
      font-size: 24px;
      color: #666;
    }
    .chart {
      // background: #283142;
      // background: #ffffff;
      border-radius: 20px;
      width: 100%;
      height: 35vh;
      overflow: hidden;
    }
  }
  .block {
    margin-left: 10px;
  }
  .ancklick {
    display: none;
    width: 500px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      font-weight: 900;
      padding: 10px;
      color: #fff;
      background-color: #009fe9a8;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;
      .iconqq {
        cursor: pointer;
        color: #ffffff;
      }
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
    }
  }
  .anc2 {
    display: block;
  }
}
</style>