import XLSX from 'xlsx';

function autoWidth (ws, data) {
    const colWidth = data.map(row => row.map(val => {
        const _val = String(val);
        if (!_val) return { wch: 10 };
        const length = (_val.match(/[\u3220-\uFA29]/g) || []).length;
        return { wch: _val.length + length };
    }));
    ws['!cols'] = colWidth[0].map((col, index) => ({ wch: Math.max(...colWidth.map(i => i[index].wch)) }));
}

function getValues (keys, jsonData) {
    return jsonData.map(v => keys.map(key => v[key]));
}

/**
 * 组件 table 数据导出
 * @param {Object[]} dataSource - 数据数组
 * @param {Object[]} columns - 列描述数据对象
 * @param {string} [fileName=Date.now()] - 文件名
 */
export function toXlsx ({ dataSource, columns, fileName = String(Date.now()),sheet }) {
    const { key, title } = columns.reduce((obj, value) => {
        obj.key.push(value.value || value.prop);
        obj.title.push(value.label);
        return obj;
    }, { key: [], title: [] });
    const wb = XLSX.utils.book_new();
    const arr = getValues(key, dataSource);
    arr.unshift(title);
    const ws = XLSX.utils.aoa_to_sheet(arr);
    autoWidth(ws, arr);
    XLSX.utils.book_append_sheet(wb, ws, sheet);
    XLSX.writeFile(wb, fileName + '.xlsx');
}

/**
 * 组件 table 数据导出
 * @param {Object[]} file - 上传的excl
 * @param {Object[]} columns - 列描述数据对象
 */


export function xlsxToJson({fileData,columns}) {
    const { key, title } = columns.reduce((obj, value) => {
        obj.key.push(value.value || value.prop);
        obj.title.push(value.label);
        return obj;
    }, { key: [], title: [] });
    let jsonData = []
    fileData.forEach(item => {
        let dataObj = {}
        key.forEach((v,k) => {
            dataObj[v] = item[title[k]]
        })
        jsonData.push(dataObj)
        
    })
    return jsonData
}

