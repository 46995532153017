import { render, staticRenderFns } from "./gisdetail.vue?vue&type=template&id=e10cc752&scoped=true&"
import script from "./gisdetail.vue?vue&type=script&lang=js&"
export * from "./gisdetail.vue?vue&type=script&lang=js&"
import style0 from "./gisdetail.vue?vue&type=style&index=0&id=e10cc752&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e10cc752",
  null
  
)

export default component.exports